import { isWechatAgent } from '@zelos/common';
import { useRequest } from 'ahooks';
import { SpinLoading } from 'antd-mobile';
import axios from 'axios';
import { createContext, lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import H5Config from './config';
import MainView from './routes/main';
import { getCurrentUserInfo, getPermissions } from './service';
import { getQueryVariable, request } from './utils';

const TrailCar = lazy(() => import('./routes/trail-car'));
const Map = lazy(() => import('./routes/map'));
const QuestionSubmit = lazy(() => import('./routes/question-submit'));
const Case = lazy(() => import('./routes/case'));
const ChongqingRace = lazy(() => import('./routes/chongqing-race'));
const DailyCheck = lazy(() => import('./routes/daily-check'));
const Transfer = lazy(() => import('./routes/data-transfer'));
const FlowView = lazy(() => import('./routes/flow'));
const ApproveView = lazy(() => import('./routes/flow/component/approve'));
const MessagesView = lazy(() => import('./routes/flow/component/messages'));
const OtaFlowView = lazy(() => import('./routes/flow/component/ota'));
const VehicleCaseFlowView = lazy(
  () => import('./routes/flow/component/vehicle-case'),
);
const Help = lazy(() => import('./routes/help'));
const MapCollect = lazy(() => import('./routes/map-collect'));
const MapCollectVehicleMode = lazy(
  () => import('./routes/map-collect-vehicle-mode'),
);
const ItemList = lazy(
  () => import('./routes/map-collect/detail/component/item-list-modal'),
);
const OfflineProblem = lazy(() => import('./routes/offline-problem'));
const PmView = lazy(() => import('./routes/pm'));
const Questionnaire = lazy(() => import('./routes/questionnaire'));
const RouteAuditPage = lazy(() => import('./routes/route-audit'));
const RouteDetailPage = lazy(() => import('./routes/route-audit/route-detail'));
const RouteDelivery = lazy(() => import('./routes/route-delivery'));
const TakeoverReasonView = lazy(() => import('./routes/takeoverReason'));
const Page403 = lazy(() => import('./routes/page-403'));
const Clue = lazy(() => import('./routes/clue'));

export const PermissionContext = createContext<string[]>([]);

const DELIVERY_APP_UA = 'com.zelostech.delivery';

function App() {
  const access_token = getQueryVariable('access_token');
  const login = request.ssoManager.isLogin() || !!access_token;

  const [permissions, setPermissions] = useState<string[]>([]);

  const { run: getPermission } = useRequest(getPermissions, {
    manual: true,
    onSuccess: (res) => {
      if (res.success) {
        if (
          !res.data?.includes('H5App') &&
          window.location.pathname !== '/403' &&
          !window.location.pathname.startsWith('/questionnaire/')
        ) {
          window.location.href = '/403';
          return;
        }

        setPermissions(res.data);
      }
    },
  });

  useEffect(() => {
    if (access_token) {
      getCurrentUserInfo().then((res) => {
        if (res?.success) {
          localStorage.setItem('mobile_number', res?.data?.mobileNumber);
          localStorage.setItem('username', res?.data?.username);
          localStorage.setItem(
            'organizationId',
            res?.data?.organization?.id.toString() ?? '',
          );

          const userId = res?.data?.id.toString();

          localStorage.setItem('userId', userId ?? '');

          if (window.apmPlus && userId) {
            window.apmPlus('config', { userId });
          }
        }
      });
    }
  }, [access_token]);

  useEffect(() => {
    const _login = request.ssoManager.isLogin();
    const code = getQueryVariable('code');
    const grantType = getQueryVariable('grant_type');
    const access_token = getQueryVariable('access_token');
    const username = decodeURI(getQueryVariable('username'));
    const organizationId = getQueryVariable('organizationId');
    const userId = getQueryVariable('userId');
    const state = getQueryVariable('state');
    console.log('code: ', code);
    if (access_token) {
      localStorage.removeItem('username');
      localStorage.removeItem('access_token');
      localStorage.removeItem('organizationId');
      localStorage.removeItem('userId');
      localStorage.setItem('username', username);
      localStorage.setItem('access_token', access_token);
      localStorage.setItem('organizationId', organizationId);
      localStorage.setItem('userId', userId);

      if (window.apmPlus && userId) {
        window.apmPlus('config', { userId });
      }

      return;
    }
    if (_login) {
      getPermission();
      return;
    }

    if (code) {
      // 有code参数
      if (grantType) {
        axios
          .post(
            `https://${H5Config.getDerivedAppDomain('auth')}/oauth/token`,
            {
              client_id: 'mp-web',
              client_secret: 'mp-web@123456',
              grant_type: grantType,
              code,
            },
            {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            },
          )
          .then((resp: any) => {
            const { user_name, access_token, refresh_token, user_id } =
              resp.data;

            localStorage.setItem('username', user_name);
            localStorage.setItem('access_token', access_token);
            localStorage.setItem('refresh_token', refresh_token);
            localStorage.setItem('userId', user_id);
            // 处理url
            if (state) {
              window.location.href = decodeURIComponent(state);
            } else {
              window.location.reload();
            }
          });
      } else {
        request.ssoManager.login(code).then((res: any) => {
          localStorage.setItem('username', res.user_name);
          localStorage.setItem('userId', res.user_id);
          localStorage.setItem('organizationId', res.organization_id);
          // 处理url
          if (state) {
            window.location.href = decodeURIComponent(state);
          } else {
            window.location.reload();
          }
        });
      }
    } else {
      // 未登录
      // 微信登录
      if (isWechatAgent()) {
        window.location.href = `https://open${
          window.location.hostname.includes('-uat') ? '-uat' : ''
        }.zelostech.com.cn/wxlogin?redirect_uri=${window.location.href}`;
      } else {
        request.ssoManager.gotoLoginPage(true);
      }
    }
  }, [getPermission]);

  console.log('login', login);

  useEffect(() => {
    if (login) {
      getPermission();
    }
  }, [login, getPermission]);

  if (!login) {
    return null;
  }

  return (
    <BrowserRouter>
      <PermissionContext.Provider value={permissions}>
        <Routes>
          <Route
            path="/"
            element={
              <MainView
                fromApp={
                  !!getQueryVariable('access_token') ||
                  window.navigator.userAgent.includes(DELIVERY_APP_UA)
                }
              />
            }
          />
          <Route
            path="/problem"
            element={
              <SuspenseWrapper>
                <QuestionSubmit />
              </SuspenseWrapper>
            }
          />
          <Route
            path="/takeoverReason"
            element={
              <SuspenseWrapper>
                <TakeoverReasonView />
              </SuspenseWrapper>
            }
          />
          <Route
            path="/pm"
            element={
              <SuspenseWrapper>
                <PmView />
              </SuspenseWrapper>
            }
          />
          <Route
            path="/flow"
            element={
              <SuspenseWrapper>
                <FlowView />
              </SuspenseWrapper>
            }
          />
          <Route
            path="/viewApprove"
            element={
              <SuspenseWrapper>
                <ApproveView />
              </SuspenseWrapper>
            }
          />
          <Route
            path="/transfer/*"
            element={
              <SuspenseWrapper>
                <Transfer />
              </SuspenseWrapper>
            }
          />
          <Route
            path="/dailyCheck/*"
            element={
              <SuspenseWrapper>
                <DailyCheck />
              </SuspenseWrapper>
            }
          />
          <Route
            path="/map/*"
            element={
              <SuspenseWrapper>
                <Map />
              </SuspenseWrapper>
            }
          />
          <Route
            path="/help"
            element={
              <SuspenseWrapper>
                <Help />
              </SuspenseWrapper>
            }
          />
          <Route
            path="/vehicleCaseFlow"
            element={
              <SuspenseWrapper>
                <VehicleCaseFlowView />
              </SuspenseWrapper>
            }
          />
          <Route
            path="/messageList"
            element={
              <SuspenseWrapper>
                <MessagesView />
              </SuspenseWrapper>
            }
          />
          <Route
            path="/otaFlow"
            element={
              <SuspenseWrapper>
                <OtaFlowView />
              </SuspenseWrapper>
            }
          />
          <Route
            path="/offlineProblem"
            element={
              <SuspenseWrapper>
                <OfflineProblem />
              </SuspenseWrapper>
            }
          />
          <Route
            path="/trailCar"
            element={
              <SuspenseWrapper>
                <TrailCar />
              </SuspenseWrapper>
            }
          />
          <Route
            path="/mapCollect/*"
            element={
              <SuspenseWrapper>
                <MapCollect />
              </SuspenseWrapper>
            }
          />
          <Route
            path="/chongqingRace"
            element={
              <SuspenseWrapper>
                <ChongqingRace />
              </SuspenseWrapper>
            }
          />
          <Route
            path="/circleItems/:id"
            element={
              <SuspenseWrapper>
                <ItemList />
              </SuspenseWrapper>
            }
          />
          <Route
            path="/questionnaire/:id"
            element={
              <SuspenseWrapper>
                <Questionnaire />
              </SuspenseWrapper>
            }
          />
          <Route
            path="/mapCollectVehicleMode/*"
            element={
              <SuspenseWrapper>
                <MapCollectVehicleMode />
              </SuspenseWrapper>
            }
          />
          <Route
            path="/routeDelivery/*"
            element={
              <SuspenseWrapper>
                <RouteDelivery />
              </SuspenseWrapper>
            }
          />
          <Route
            path="/routeAudit"
            element={
              <SuspenseWrapper>
                <RouteAuditPage />
              </SuspenseWrapper>
            }
          />
          <Route
            path="/routeDetail"
            element={
              <SuspenseWrapper>
                <RouteDetailPage />
              </SuspenseWrapper>
            }
          />
          <Route
            path="/case"
            element={
              <SuspenseWrapper>
                <Case />
              </SuspenseWrapper>
            }
          />
          <Route
            path="/403"
            element={
              <SuspenseWrapper>
                <Page403 fromApp={!!getQueryVariable('access_token')} />
              </SuspenseWrapper>
            }
          />
          <Route
            path="/clue/*"
            element={
              <SuspenseWrapper>
                <Clue />
              </SuspenseWrapper>
            }
          />
        </Routes>
      </PermissionContext.Provider>
    </BrowserRouter>
  );
}

const SuspenseWrapper = (props: any) => {
  const loadingDom = (
    <div>
      <SpinLoading
        style={{
          '--size': '48px',
          position: 'fixed',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}
        color="primary"
      />
    </div>
  );

  return (
    <Suspense fallback={loadingDom}>
      <>{props.children}</>
    </Suspense>
  );
};

export default App;
