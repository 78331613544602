import { Mask, SpinLoading } from 'antd-mobile';
import { FC } from 'react';

const PageLoading: FC<{ loading: boolean }> = ({ loading }) => {
  return (
    <Mask visible={loading}>
      <SpinLoading
        color="primary"
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      />
    </Mask>
  );
};

export default PageLoading;
