import './theme';

import { ConfigProvider, PullToRefresh } from 'antd-mobile';
import zhCN from 'antd-mobile/es/locales/zh-CN';
import ReactDOM from 'react-dom/client';
import VConsole from 'vconsole';
import App from './App';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

declare global {
  interface Window {
    __vconsole__: any;
    changeModel: (str: string) => void;
  }
}

if (process.env.NODE_ENV === 'development' && !window.__vconsole__) {
  window.__vconsole__ = new VConsole();
}
// const isWechat = () => {
//   var ua = window.navigator.userAgent.toLowerCase();
//   // @ts-ignore
//   if (ua.match(/micromessenger/i) == 'micromessenger') {
//     return true;
//   } else {
//     return false;
//   }
// };
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <ConfigProvider locale={zhCN}>
    {/* @ts-ignore */}
    <PullToRefresh onRefresh={() => window.location.reload()}>
      <App />
    </PullToRefresh>
  </ConfigProvider>,
);

serviceWorkerRegistration.unregister();
